<template>
  <div>

   <h1>
     Кампании
     <router-link to="/partner/campaign/new" class="btn btn-primary">
       Создать кампанию
     </router-link>
   </h1>

    <Report ref="report" url="campaign/report" :def-params="{order: {field: 'campaign_id', order: 'desc'}, filters: {'account_id': 'eq|' + user.account_id }}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <div v-if="error">
        {{error}}
      </div>
      <div v-else>

          <div>

            <div v-if="report">
              <div v-if="report.rows_count === 0">
                  У вас ещё нет ни одной кампании.
              </div>

              <template v-else>
                <table class="table" width="100%" cellspacing="0" cellpadding="0">
                 <thead>
                 <tr>
                   <td width="40">Код</td>
                   <td>Название</td>
                   <td width="auto">Партнёрская ссылка</td>

                   <td>Визиты</td>
                   <td>Заказы</td>
                   <td>CR</td>

                   <td width="120">Статус</td>
                   <td width="60"></td>
                 </tr>
                 </thead>
                  <tbody>
                  <tr
                      v-for="(row, index) in report.rows"
                      v-bind:key="index"
                  >

                    <td>
                      {{row.campaign_id}}
                    </td>
                    <td>
                      <router-link :to="`/partner/campaign/${row.campaign_id}`">
                        {{row.campaign_title}}
                      </router-link>
                    </td>
                    <td>
                     vizit.tours?p={{row.campaign_id}}
                    </td>
                    <td>
                      {{ row.cnt_visit }}
                    </td>
                    <td>
                      {{ row.cnt_order }}
                    </td>
                    <td>
                      {{ row.cnt_cr }}%
                    </td>

                    <td>
                      <span :class="`status status_${row.campaign_status}`">
                        {{status[row.campaign_status]}}
                      </span>
                    </td>
                    <td>
                      <div class="nav-right">
                        <a class="nav"></a>
                        <div class="nav-con">
                          <a href="#" v-if="row.campaign_status === 'active'" v-on:click.prevent="changeStatus(row, 'paused')">Заблокировать</a>
                          <a href="#" v-if="row.campaign_status === 'paused'" v-on:click.prevent="changeStatus(row, 'active')">Разблокировать</a>
                          <a href="#" v-on:click.prevent="changeStatus(row, 'deleted')">Удалить</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </template>
            </div>
          </div>


        <Pager />
      </div>
    </Report>

    <Nav />
  </div>
</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';

import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';

import User from '../../../user';
import Nav from "@/pages/partner/nav";

export default {
  mixins: [mixins],

  components: {
    Report,
    Pager,
    Nav
  },

  created() {

  },

  data() {
    return {
      showOrder: null,
      user: User.currentUser.user || {},
      data: {
        order: {
          is_partial: false,
          place_data_set: null,
        }
      },
      status: {
        'active': 'Активна',
        'paused': 'Заблокирована',
      },
      error: null,
      report: null
    }
  },

  methods: {

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report.response) {
        this.report = report.response;
        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }
    },

    getReport() {
      return this.$refs.report;
    },

    async changeStatus(row, status) {
      row.campaign_status = status;
      await Ajax.post(`/campaign/save/${row.campaign_id}`, row);
      this.getReport().reload();
    },
  }
}
</script>
