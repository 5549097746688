<template>
  <div>
    <h1 class="tab-header">
      <span>
        Статистика
      </span>
    </h1>

    <div>

      <div class="prices big-price" v-if="amount && amount.summary">
        <div class="prices-item">
          {{formatRUB(amount.summary.seller_summary.sum_salary - amount.summary.seller_summary.sum_salary_freeze)}}
          <div>
            Баланс
          </div>
        </div>

        <div class="prices-item">
          {{formatRUB(amount.summary.seller_summary.sum_salary_freeze)}}
          <div>
            Ожидает зачисления
          </div>
        </div>

        <div class="prices-item">
          {{formatRUB(amount.summary.seller_summary.sum_collection_award)}}
          <div>
            Выведено за всё время
          </div>
        </div>
      </div>
    </div>
    <Nav />
  </div>
</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Nav from "@/pages/partner/nav";

import User from '../../../user';

export default {
  mixins: [mixins],

  components: {
    Nav
  },


  data() {
    return {
        amount: {},
        account_id: User.currentUser.account.account_id || 0,
      }
  },

  created() {
    this.loadAmount()
  },

  methods: {

  async loadAmount() {
    let result = await Ajax.report(`/collection/report/`, {order: {field: 'collection_id', order: 'desc'}, filters: {'t.account_id': 'eq|' + this.account_id}});
    if (result.response) {
      this.amount = result.response;
    }
  }

  }
}
</script>
