<template>
 <div>
   <a class="navigate-toggle" :class="{showed: show}" v-on:click.prevent="show = !show"></a>
   <div class="navigate" :class="{showed: show}">
    <div>
      <router-link to="/partner/ex" v-on:click="show = false">
        Статистика
      </router-link>
      <router-link to="/partner/campaign/">
        Кампании
      </router-link>
      <router-link to="/partner/salary/">
       Начисления
      </router-link>
      <router-link to="/partner/cash/">
        Вывод средств
      </router-link>
      <router-link to="/partner/profile">
        Профиль
      </router-link>
    </div>
     <div class="size">
       <div class="radio">
         <label>
           <input type="checkbox" name="font_size" v-model="size">
           <span></span>
           <div>Увеличенный размер</div>
         </label>
       </div>
     </div>
     <div class="navigate-footer">
       <router-link to="/logout">
         Выход
       </router-link>
       <a v-on:click.prevent="reload">
         <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 2.001c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-4.496 6.028-.002-.825c0-.414-.336-.75-.75-.75s-.75.336-.75.75v3.048c0 .414.336.75.75.75h3.022c.414 0 .75-.336.75-.75s-.336-.756-.75-.756h-1.512c.808-1.205 2.182-1.998 3.74-1.998 2.483 0 4.5 2.016 4.5 4.5 0 2.483-2.017 4.5-4.5 4.5-1.956 0-3.623-1.251-4.242-2.997-.106-.299-.389-.499-.707-.499-.518 0-.88.513-.707 1.001.825 2.327 3.048 3.995 5.656 3.995 3.312 0 6-2.689 6-6 0-3.312-2.688-6-6-6-1.79 0-3.399.786-4.498 2.031z" fill-rule="nonzero"/></svg>
       </a>
     </div>
   </div>
 </div>
</template>

<script>

export default {

  created() {
    if (this.size !== false) {
      document.getElementsByTagName('body')[0].classList = 'big'
    }
  },

  watch: {
    size() {
      localStorage.setItem('size', this.size)
      document.getElementsByTagName('body')[0].classList = this.size ? 'big' : ''
    }
  },

  data() {
    return {
      size: localStorage.getItem('size') && localStorage.getItem('size') === 'true' || false,
      show: false
    }
  },
  methods: {
    reload() {
      window.location.reload()
    }
  }

}

</script>