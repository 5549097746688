<template>
  <section class="auth-main">
    <div class="auth">
      <div>
        <h1>
          Авторизация
        </h1>

        <div v-if="errorText !== ''" class="error-box">{{errorText}}</div>

        <Input name="user_login" mask="+#(###)###-##-##" text="Телефон" required="true" v-model="user_login" />
        <Input name="user_password" text="Пароль" required="true" type="password" v-model="user_password" />

        <div class="submit">
          <div class="g2">
            <div>
              <button class="btn btn-primary" v-on:click="login">Войти</button>
              <router-link style="margin-left: 16px" to="/auth/forgot/">Забыли пароль?</router-link>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import User from '../../user';
import Input from '../../components/Input';

export default {
  components: {
    Input
  },

  data () {
    return {
      user_login: '',
      user_password: '',
      recaptcha: '',
      showCaptcha: false,
      errorText: ''
    }
  },
  methods: {
    verifyRecaptcha(response) {
      this.recaptcha = response;
    },

    resetRecaptcha() {
      this.recaptcha = '';
    },

    async login () {
      this.errorText = '';

      if ((!this.user_login) || (this.user_password.length < 5)) {
        this.errorText = 'Неверный номер телефона или пароль';
        return;
      }

      let result = await User.login(this.user_login.match(/[\d]/g).join(''), this.user_password, 99999);
      if (result === true) {
        this.showCaptcha = false;
      } else {
        this.recaptcha = '';
        if (this.$refs.recaptcha) {
          this.$refs.recaptcha.reset();
        }

        const errors = {
          299: 'Некорректный телефон',
          300: 'Некорректный телефон или пароль',
          303: 'Некорректный телефон или пароль',
          307: 'Используйте телефон для входа',
          333: 'Ваш аккаунт заблокирован'
        };

        if (errors[result.code]) {
          this.errorText = errors[result.code];
          return;
        }

        if (result.code === 304) {
          this.showCaptcha = true;
          this.errorText = 'Невозможно проверить капчу. Пожалуйста, попробуйте снова.';
          return;
        }

        this.errorText = 'Что-то пошло не так:(';
      }
    }
  }
}
</script>