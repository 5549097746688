<template>
  <div>

   <h1>История начислений</h1>

    <Report ref="report" url="salary/report" :def-params="{order: {field: 'salary_id', order: 'desc'}, filters: {'t.account_id': 'eq|' + user.account_id }}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <div v-if="error">
        {{error}}
      </div>
      <div v-else>

          <div class="card-report">

            <div class="card-report_filter">
              <PeriodPickerFilter placeholder="Период" :allow-future="true" name="t.ts" />
            </div>

            <div v-if="report">
              <div v-if="report.rows_count === 0">
                  У вас нет начислений за выбранный период.
              </div>

              <template v-else>

                <div class="summary">
                  <div>
                    <b>{{ formatRUB(report.summary.income) }}</b>
                    <i>Итого</i>
                  </div>

                  <div>
                    <b>{{ formatRUB(report.summary.trx_income_available) }}</b>
                    <i>Зачислено</i>
                  </div>

                  <div>
                    <b>{{ formatRUB(report.summary.trx_income_freeze) }}</b>
                    <i>Ожидает зачисления</i>
                  </div>

                  <div>
                    <b>{{ formatRUB(report.summary.trx_outcome) }}</b>
                    <i>Сумма возвратов</i>
                  </div>
                </div>


                <table class="table" width="100%" cellspacing="0" cellpadding="0">
                  <thead>
                  <tr>
                    <td width="40">№</td>
                    <td>Кампания</td>
                    <td>Дата продажи</td>
                    <td>Дата зачисления</td>
                    <td>Сумма</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(row, index) in report.rows"
                      v-bind:key="index"
                      :class="{
                        warning: row.salary_amount < 0
                      }"
                  >

                    <td>{{row.order_id}}</td>
                    <td> {{row.campaign_title}}</td>
                    <td>{{convertDate(row.ts)}}</td>
                    <td>{{convertDate(row.salary_freeze_date)}}</td>
                    <td><b>{{formatRUB(row.salary_amount)}}</b></td>

                  </tr>
                  </tbody>
                </table>



              </template>
            </div>
          </div>


        <Pager />
      </div>
    </Report>

    <Nav />
  </div>
</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';

import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';
import PeriodPickerFilter from '../../../components/report/PeriodPickerFilter';


import moment from "moment";

import User from '../../../user';
import Nav from "@/pages/partner/nav";

export default {
  mixins: [mixins],

  components: {
    Report,
    Pager,
    PeriodPickerFilter,
    Nav
  },

  created() {

  },

  data() {
    return {
      showOrder: null,
      user: User.currentUser.user || {},
      data: {
        order: {
          is_partial: false,
          place_data_set: null,
        }
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован',
        'order': 'Оплачен',
        'cancel': 'Отменён',
        'cancel_success': 'Возврат',
        'waiting_for_capture': 'Оплачен, не подтверждён',
        'pending': 'Ожидает оплаты'
      },
      error: null,
      report: null
    }
  },

  methods: {

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report.response) {
        this.report = report.response;
        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }
    },

    async changeStatus(row, status) {
      row.status = status;
      await Ajax.post(`/order/cancel/${row.order_id}`, row);
      this.getReport().reload();
    },


    changePlaceIds(val) {
      this.data.order.order_place = val.value;

      if (this.data.order.place_time && this.data.order.place_time['t' + val.value]) {
        let i = this.data.order.place_time['t' + val.value];
        let time = 0
        if (i.indexOf('-') > -1) {
          time = moment(this.data.order.order_date_ot).subtract(i.replace('-', ''), 'minutes').format('HH:mm')
        } else {
          time = moment(this.data.order.order_date_ot).add(i, 'minutes').format('HH:mm')
        }

        this.data.order.order_place_time = time

      }
    },


  }
}
</script>
