import Ajax from './helpers/ajax'
import EventEmitter from './helpers/event'

class User extends EventEmitter {

    async loadCurrentUser(query = null) {
        let url = 'whoami';

        if (query) {
            url += `?${query}`;
        }
        if (typeof dataLayer === 'undefined') {
            window.dataLayer = [];
        }

        let resp = await Ajax.get(url);

        if (resp.response) {
            this.currentUser = resp.response;

            if ((this.currentUser) && (this.currentUser.user)) {
                dataLayer.push({user_id: this.currentUser.user.account_id});
            }
        } else {
            this.currentUser = { is_logged_in: false };
            dataLayer.push({user_id: 0});
        }

        this.emit('change', [this.currentUser.is_logged_in]);
    }

    isLoggedIn() {
        return (this.currentUser && this.currentUser.is_logged_in && this.currentUser.account.account_status === 'active');
    }

    isImpersonate() {
        return (this.currentUser && this.currentUser.is_impersonate);
    }

    getHomepage() {
        if (this.isLoggedIn()) {

            switch (this.currentUser.user.role_type) {
                case 'adm':
                    return '/partner/off';
                case 'staff':

                    switch (this.currentUser.account.account_post) {
                        case 6:
                            return '/partner/ex';
                        default:
                            return '/partner/off';
                    }
                case 'partner':
                    return '/partner/ex';
            }

            return '/partner/off';
        }

        return '/auth/login';
    }

    async impersonate(user_id) {
        await Ajax.get(`/auth/impersonate?user_id=${user_id}`);
        await this.loadCurrentUser();

        this.emit('refresh', [this.currentUser.is_logged_in]);
    }

    async login(user_login, user_password, recaptcha = '', auth = false) {
        let resp = await Ajax.post('auth/login', {user_login, user_password, 'g-recaptcha-response': recaptcha, role_type: 'partner', auth});

        if (resp.response) {
            await this.loadCurrentUser();
            this.emit('refresh', [true]);
            return true;
        } else {
            return resp.error;
        }
    }

    async signup(data) {
        return await Ajax.post('auth/signup', data);
    }

    async logout () {
        await Ajax.get('auth/logout');
        await this.loadCurrentUser();

        this.emit('refresh', [this.currentUser.is_logged_in]);
    }

    async forgot(user_phone, auth_code = 0) {
        let resp = await Ajax.post('auth/forgot', {user_phone, auth_code});

        return resp.response ? resp.response : resp.error;
    }

    async reset(uid, h) {
        return await Ajax.get('auth/reset?uid=' + uid + '&h=' + h);
    }

}

export default new User();