<template>
    <section class="auth-main">
        <div class="auth">
            
            <div v-if="is_forgot">
                <h1>
                   Пожалуйста, проверьте свой телефон.
                </h1>
                <div class="h1-desc" style="margin-bottom: 16px">
                    Мы отправили проверочный код на <br>
                  {{user_login}}
                </div>
              <Input name="auth_code" text="Код из СМС" type="text" required="true" v-model.trim="auth_code" />
              <div class="submit">
                <div class="g2">
                  <div>
                    <button class="btn btn-primary" v-on:click="forgot">Отправить</button>
                    <router-link style="margin-left: 16px" to="/auth/login">Войти</router-link>
                  </div>

                </div>
              </div>
            </div>

            <div v-else>
                <h1>
                    Напомнить пароль?
                </h1>

                <div v-if="errorText" class="error-box">{{errorText}}</div>

              <div>
                <Input v-if="!is_forgot" mask="+#(###)###-##-##" text="Введите свой номер телефона" name="user_login" type="text" required="true" v-model.trim="user_login" />

              </div>

                <div class="submit">
                    <div class="g2">
                        <div>
                            <button class="btn btn-primary" v-on:click="forgot">Отправить</button>
                            <router-link style="margin-left: 16px" to="/auth/login">Войти</router-link>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    import User from '../../user';
    import Input from '../../components/Input';

    export default {
        components: {
            Input
        },

        data () {
            return {
              is_forgot: false,
              account_title: '',
              auth_code: null,
              user_login: '',
              user_phone: '',
              user_password: '',
              confirm_password: '',
              recaptcha: '',
              showCaptcha: false,
              errorText: '',
            }
        },
        methods: {
            verifyRecaptcha(response) {
                this.recaptcha = response;
            },

            resetRecaptcha() {
                this.recaptcha = '';
            },

          async forgot () {
            this.errorText = '';

            if (!this.user_login) {
              this.errorText = 'Введите номер телефона';
              return;
            }

            if (!this.is_forgot) {
              let result = await User.forgot(this.user_login);

              if (result.status === 200) {
                this.is_forgot = true;
              } else {

                const errors = {
                  299: 'Неверный номер телефона',
                  308: 'Не нашлось аккаунта с таким номером телефона'
                };

                if (errors[result.code]) {
                  this.errorText = errors[result.code];
                  return;
                }

                if (result.code === 304) {
                  this.showCaptcha = true;
                  this.errorText = 'Невозможно проверить капчу. Пожалуйста, попробуйте снова.';
                  return;
                }

                this.errorText = 'Что-то пошло не так:(';
              }
            } else {
              if (!this.auth_code) {
                this.errorText = 'Введите код из смс';
                return;
              }
              let result = await User.forgot(this.user_login, this.auth_code);

              if (result) {
                if (result.auth_code) {
                  this.errorText = result.auth_code;
                  return;
                }
                if (result.auth) {

                  let resultLogin = await User.login(this.user_login.match(/[\d]/g).join(''), 99999, this.recaptcha, true);

                  if (resultLogin === true) {
                    this.showCaptcha = false;
                  }

                  //  this.$router.push({ path: '/cabinet/profile' });
                }
              }
            }
          }
        }
    }
</script>