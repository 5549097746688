<template>
  <section class="auth-main">
    <div class="auth">
      <div>
        <h1>
          Стать партнёром
        </h1>

        <div v-if="errorText !== ''" class="error-box">{{errorText}}</div>

        <Input name="user_login" text="Электронная почта" type="mail" required="true" v-model.trim="user_login" />
        <Input name="user_phone" mask="+7(###)###-##-##" text="Номер телефона" type="text" required="true" v-model.trim="user_phone" />
        <Input name="account_title" text="ФИО" type="text" required="true" v-model.trim="account_title" />
        <Input name="user_password" text="Придумайте пароль" type="password" required="true" v-model.trim="user_password" />
        <Input name="confirm_password" text="Повторите пароль" type="password" required="true" v-model.trim="confirm_password" />
        <div class="submit">
          <div class="g2">
            <div>
              <button class="btn btn-primary" v-on:click="signIn">Создать аккаунт</button>
              <router-link style="margin-left: 16px" to="/auth/login/">Уже есть аккаунт</router-link>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import mixins from '../../helpers/mixins.js';
import User from '../../user';
import Input from '../../components/Input';
import Ajax from '../../helpers/ajax';
import $ from 'jquery';

export default {
  mixins: [mixins],

  components: {
    Input
  },

  data () {
    return {
      user_login: '',
      user_phone: '',
      user_password: '',
      confirm_password: '',
      account_title: '',
      recaptcha: '',
      showCaptcha: false,
      errorText: ''
    }
  },
  methods: {
    verifyRecaptcha(response) {
      this.recaptcha = response;
    },

    resetRecaptcha() {
      this.recaptcha = '';
    },

    validMail(mail) {
      let re = /^[\w-\\.]+@[\w-]+\.[a-z]{2,4}$/i;
      return  re.test(mail);
    },

    validPhone(phone) {
      if (phone !== '') {
        phone = phone.match(/[\d]/g).join('')
        let re = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
        return  re.test(phone);
      } else {
        return false
      }
    },

    async signIn () {

      this.clearError();

      let error = {};

      if (this.confirm_password !== this.user_password) {
        error.confirm_password = 'Пароли не совпадают';
        error.user_password = 'Пароли не совпадают'
      }
      if (!this.validPhone(this.user_phone)) {
        error.user_phone = 'Укажите корректный номер телефона'
      }
      if (!this.validMail(this.user_login)) {
        error.user_login = 'Укажите корректный адрес электронной почты'
      }

      if (!$.isEmptyObject(error)) {
        this.setError(error);

        return false;
      }


      let data = {
        user_phone: this.user_phone.match(/[\d]/g).join(''),
        user_login: this.user_login,
        user_password: this.user_password,
        confirm_password: this.confirm_password,
        account_title: this.account_title,
        account_type: 'partner'
      };

      let list = [];

      list.push(
          Ajax.post(`/account/save/0`, data)
      );

      let [userResult] = await Promise.all(list);

      if (userResult.error) {
        this.setError(userResult.error);
      }

      if (userResult.response) {

        let result = await User.login(this.user_login, this.user_password, this.recaptcha);
        if (result === true) {
          this.showCaptcha = false;
        }

        this.$router.go()
      }
    },

    async login () {
      this.errorText = '';

      if ((!this.user_login) || (this.user_password.length < 5)) {
        this.errorText = 'Неверный номер телефона или пароль';
        return;
      }

      let result = await User.login(this.user_login.match(/[\d]/g).join(''), this.user_password, 99999);
      if (result === true) {
        this.showCaptcha = false;
      } else {
        this.recaptcha = '';
        if (this.$refs.recaptcha) {
          this.$refs.recaptcha.reset();
        }

        const errors = {
          299: 'Некорректный телефон',
          300: 'Некорректный телефон или пароль',
          303: 'Некорректный телефон или пароль',
          307: 'Используйте телефон для входа',
          333: 'Ваш аккаунт заблокирован'
        };

        if (errors[result.code]) {
          this.errorText = errors[result.code];
          return;
        }

        if (result.code === 304) {
          this.showCaptcha = true;
          this.errorText = 'Невозможно проверить капчу. Пожалуйста, попробуйте снова.';
          return;
        }

        this.errorText = 'Что-то пошло не так:(';
      }
    }
  }
}
</script>