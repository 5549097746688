import { createWebHistory, createRouter } from "vue-router";
import user from "./user";
import Error404 from "./pages/Error404";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Account from "./pages/auth/Account";
import Campaign from "./pages/partner/campaign/Index";
import CampaignEdit from "./pages/partner/campaign/Edit";


//Partner

import CashierCash from "./pages/partner/finance/Cash";
import CashierEx from "./pages/partner/ex/Index";
import Salary from "./pages/partner/salary/Index";
import CashierProfile from "./pages/partner/profile/Index";
import CashierOff from "./pages/partner/off";

export default async function() {
    await user.loadCurrentUser();

    const router = createRouter({
        history: createWebHistory(),
        base: __dirname,
        routes: [


            /* Cashier */
            { path: '/partner/ex', component: CashierEx },
            { path: '/partner/cash', component: CashierCash },
            { path: '/partner/salary', component: Salary },
            { path: '/partner/campaign', component: Campaign },
            { path: '/partner/campaign/:id', component: CampaignEdit },
            { path: '/partner/profile', component: CashierProfile },
            { path: '/partner/off', component: CashierOff },

            /* Account and other */
            { path: '/auth/login', component: Login },
            { path: '/auth/register', component: Register },
            { path: '/auth/forgot', component: Forgot },
            { path: '/auth/reset', component: Reset },
            { path: '/setting/account', component: Account },

            { path: '/logout',
                beforeEnter (to, from, next) {
                    user.logout();
                    next('/auth/login');
                }
            },

            { path: '/', component: Error404,
                beforeEnter(to, from, next) {
                    next(user.getHomepage());
                }
            }

        ]
    });

    router.beforeEach((to, from, next) => {

        window.scrollTo(0, 0)

        if ((to.path.indexOf('/auth/') === 0) && (user.isLoggedIn())) {
            return next({path: '/'});
        }

        if ((to.path.indexOf('/auth/') === 0)) {
            return next();
        }

        if (['/logout'].indexOf(to.path) > -1) {
            user.logout()
            return next();
        }

        if (!user.isLoggedIn()) {
            next({path: '/auth/login'});
        } else {
            if (to.path.indexOf(`/docs`) === 0) {
                return next();
            }

            if (to.path.indexOf(`/${user.currentUser.user.role_type}`) === 0) {
                return next();
            }

            if ((user.currentUser.user.role_type === 'adm') && ((to.path.indexOf(`/adm`) === 0) || (to.path.indexOf(`/dev`) === 0))) {
                return next();
            }

            return next();

        }
    });

    return router;
}
